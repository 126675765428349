.animated-arrow {
  width: 10px;
  height: 10px;
  position: relative;
  display: inline-block;
  transition: all 0.3s;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 4px;
    width: 100%;
    height: 1px;
    transition: all 0.3s;
  }
  &:before {
    transform: rotate(45deg);
    left: -1px;
  }
  &:after {
    transform: rotate(-45deg);
    left: 6px;
  }
  &.arrow-white {
    &:before,
    &:after {
      background-color: $white;
    }
  }
  &.arrow-primary {
    &:before,
    &:after {
      background-color: $blue;
    }
  }
  &.arrow-text {
    &:before,
    &:after {
      background-color: $text;
    }
  }
  &.arrow-down {
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
  &.right {
    &:before,
    &:after {
      left: 1px;
      transition: all 0.3s;
    }
    &:before {
      transform: rotate(45deg);
      top: -1px;
    }
    &:after {
      transform: rotate(-45deg);
      top: 5px;
    }
  }
  &.left {
    &:before,
    &:after {
      left: 1px;
    }
    &:before {
      transform: rotate(-45deg);
      top: -1px;
    }
    &:after {
      transform: rotate(45deg);
      top: 5px;
    }
  }
}
.dark {
  .animated-arrow {
    &.arrow-text {
      &:before,
      &:after {
        background-color: $white !important;
      }
    }
    &.arrow-primary {
      &:before,
      &:after {
        background-color: $primary-dark !important;
      }
    }
  }
}
// .coins-group {
//   position: absolute;
//   top: 0;
//   // left: 50%;
//   // transform: translateX(-50%);
//   width: 90px;
//   height: 30px;
//   background-color: red;
// }
