.answer-holder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .option-box {
    background: $body;
    border-radius: 8px;
    margin-bottom: 16px;
    padding-top: 13px;
    padding-bottom: 13px;

    // height: 140px;
    @media (max-width: 640px) {
      .mat-radio-button {
        display: inline-block !important;
      }
    }

    &.full {
      width: 100%;

      &.media {
        max-height: 150px;
        padding: 0;
        display: flex;
        overflow: hidden;

        @media (max-width: 640px) {
          display: block;
          height: auto;
          max-height: inherit;
        }

        mat-radio-button {
          margin-top: 16px;
        }

        .content {
          padding-top: 16px;
          flex-grow: 1;

          div {
            &:first-child {
              padding-right: 16px;
            }
          }

          .description {
            padding-top: 16px;
            overflow-y: auto;
            height: 90px;
          }

          @media (max-width: 540px) {
            .description {
              padding: 16px;
            }
          }
        }

        .ratio-16-9 {
          max-width: 250px;
          min-width: 250px;
          width: 250px;

          @media (max-width: 640px) {
            max-width: inherit;
            min-width: inherit;
            width: inherit;
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            cursor: pointer;
            transition: all 0.3s;
          }

          iframe {
            position: absolute;
            top: 0;
            width: 100%;
          }

          &:hover {
            img {
              opacity: 0.8;
            }
          }
        }
      }
    }

    &.option-box-small {
      width: calc(50% - 12px);
      padding-bottom: 0;
      overflow: hidden;

      @media (max-width: 540px) {
        width: 100%;
      }

      .header {
        display: flex;
        padding-bottom: 13px;
      }

      .image-container {
        position: relative;
        width: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.3s;
        }

        &:hover {
          img {
            opacity: 0.8;
          }
        }
      }

      .video-wrapper {
        width: 100%;
        overflow: hidden;

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
      }
    }
  }
}
.dark {
  .option-box {
    background: $body-dark;
  }
}
