.coin {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='56' height='56' viewBox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.90332 28.0001C0.90332 13.035 13.035 0.90332 28.0001 0.90332C42.9652 0.90332 55.0969 13.035 55.0969 28.0001C55.0969 42.9652 42.9652 55.0969 28.0001 55.0969C13.035 55.0969 0.90332 42.9652 0.90332 28.0001Z' fill='%23FFBE18'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28C56 43.464 43.464 56 28 56C12.536 56 0 43.464 0 28ZM28 1.80645C13.5337 1.80645 1.80645 13.5337 1.80645 28C1.80645 42.4663 13.5337 54.1935 28 54.1935C42.4663 54.1935 54.1935 42.4663 54.1935 28C54.1935 13.5337 42.4663 1.80645 28 1.80645Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M32.3337 14.6048C32.3337 13.0123 33.6406 11.7446 35.2237 11.7446C36.8069 11.7446 38.1137 13.0123 38.1137 14.6048V26.4649C38.1123 31.9863 33.5714 36.4411 27.9991 36.4411C26.4493 36.4411 24.9791 36.0968 23.6645 35.481L23.6645 38.325C23.6645 39.9175 22.3577 41.1852 20.7745 41.1852C19.1914 41.1852 17.8845 39.9175 17.8845 38.325V26.4649C17.8845 20.9424 22.4259 16.4887 27.9991 16.4887C29.5489 16.4887 31.0191 16.833 32.3337 17.4489V14.6048ZM27.9991 22.209C25.5922 22.209 23.6645 24.1273 23.6645 26.4649C23.6645 28.8026 25.5922 30.7208 27.9991 30.7208C30.406 30.7208 32.3337 28.8026 32.3337 26.4649C32.3337 24.1273 30.406 22.209 27.9991 22.209Z' fill='black'/%3E%3C/svg%3E%0A");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.coins-group {
  position: absolute;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   top: 0;
  width: 36px;
  height: 16px;
  .coins-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
    .coin {
      position: absolute;
      top: -12px;
      &:nth-of-type(1) {
        animation-name: first;
        animation-duration: 1s;
      }
      &:nth-of-type(2) {
        animation-name: second;
        animation-duration: 1s;
        animation-delay: 0.3s;
      }
      &:nth-of-type(3) {
        animation-name: third;
        animation-duration: 1s;
        animation-delay: 0.3s;
      }
    }
    .coin {
      position: absolute;
      top: -12px;
      opacity: 0;
      &:nth-of-type(1) {
        left: 0;
        transition: 1s;
      }
      &:nth-of-type(2) {
        left: 9px;
      }
      &:nth-of-type(3) {
        right: 0;
      }
    }
  }
}
#coinAnimationPocket {
  position: absolute;
  left: 0;
  top: -20px;
  width: 127px;
  height: 60px;
  .coins-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    opacity: 0;
    .coin {
      position: absolute;
      &:nth-of-type(1) {
        left: 0;
        top: 3px;
      }
      &:nth-of-type(2) {
        right: 0;
        top: 8px;
      }
      &:nth-of-type(3) {
        left: 15px;
        top: 42px;
      }
      &:nth-of-type(4) {
        left: 36px;
        top: 35px;
      }
      &:nth-of-type(5) {
        left: 66px;
        top: 9px;
      }
      &:nth-of-type(6) {
        left: 87px;
        top: 35px;
      }
      &:nth-of-type(7) {
        left: 22px;
        top: 18px;
      }
    }
    .coin {
      position: absolute;
      &:nth-of-type(1) {
        animation-name: acoin;
        animation-duration: 1.6s;
        animation-iteration-count: infinite;
      }
      &:nth-of-type(2) {
        animation-name: bcoin;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
      }
      &:nth-of-type(3) {
        animation-name: ccoin;
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
      }
      &:nth-of-type(4) {
        animation-name: dcoin;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
      &:nth-of-type(5) {
        animation-name: ecoin;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
      }
      &:nth-of-type(6) {
        animation-name: fcoin;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
      &:nth-of-type(7) {
        animation-name: gcoin;
        animation-duration: 0.6s;
        animation-iteration-count: infinite;
      }
    }
  }
  &.show {
    .coins-wrapper {
      opacity: 1;
    }
  }
}

.earning {
  .coins-group {
    .coins-wrapper {
      .coin {
        position: absolute;
        top: -12px;
        &:nth-of-type(1) {
          animation-name: first;
          animation-duration: 1s;
        }
        &:nth-of-type(2) {
          animation-name: second;
          animation-duration: 1s;
          animation-delay: 0.3s;
        }
        &:nth-of-type(3) {
          animation-name: third;
          animation-duration: 1s;
          animation-delay: 0.3s;
        }
      }
    }
  }
  #coinAnimationPocket {
    .coins-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      opacity: 1;
      .coin {
        position: absolute;
        &:nth-of-type(1) {
          animation-name: acoin;
          animation-duration: 1.6s;
          animation-iteration-count: infinite;
        }
        &:nth-of-type(2) {
          animation-name: bcoin;
          animation-duration: 1.4s;
          animation-iteration-count: infinite;
        }
        &:nth-of-type(3) {
          animation-name: ccoin;
          animation-duration: 1.2s;
          animation-iteration-count: infinite;
        }
        &:nth-of-type(4) {
          animation-name: dcoin;
          animation-duration: 1s;
          animation-iteration-count: infinite;
        }
        &:nth-of-type(5) {
          animation-name: ecoin;
          animation-duration: 0.8s;
          animation-iteration-count: infinite;
        }
        &:nth-of-type(6) {
          animation-name: fcoin;
          animation-duration: 1s;
          animation-iteration-count: infinite;
        }
        &:nth-of-type(7) {
          animation-name: gcoin;
          animation-duration: 0.6s;
          animation-iteration-count: infinite;
        }
      }
    }
  }
}
@keyframes first {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  25% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}
@keyframes second {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  25% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
@keyframes third {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  25% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-40px);
  }
}

@keyframes acoin {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes bcoin {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(0.6);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes ccoin {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(0.8);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes dcoin {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(0.4);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes ecoin {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes fcoin {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(0.7);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes gcoin {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(0.7);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}
