app-proposal-create {
  background-color: rgba($body, 0.8);
  // backdrop-filter: blur(4px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;

  section {
    width: 840px;
    height: 100%;
    background-color: $white;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: -6px 0 30px 0 rgba($black, 0.1);
    @media (max-width: 991px) {
      width: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .tabs-body {
    flex-grow: 1;
    padding: 24px;
    overflow-y: auto;
  }

  .round {
    background-color: $body;
    height: 64px;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;
    border: solid thin transparent;
    transition: all 0.3s;
    cursor: pointer;
    transition: all 0.3s;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 20px;
      transform: translateY(-50%);
      left: 8px;
      width: 16px;
      height: 16px;
      border: solid thin $border;
      border-radius: 8px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 20px;
      transform: translateY(-50%);
      left: 11px;
      width: 10px;
      height: 10px;
      border-radius: 8px;
    }

    &:hover {
      background-color: lighten($body, 2%);
    }
    &.active {
      border-color: $blue;

      &:before {
        content: '';
        position: absolute;
        border: solid thin $blue;
      }
      &:after {
        background: $blue;
      }

      &:hover {
        background-color: $body;
      }
    }
    &.details {
      height: 268px;
      @media (max-width: 640px) {
        height: auto;
      }
    }
    @media (max-width: 640px) {
      height: 96px;
    }
  }

  image-cropper {
    .overlay,
    .cropper {
      margin-left: 0 !important;
    }
  }

  .add-media {
    background-color: $body;
    border: dashed 1px $border;
    border-radius: 8px;
    display: flex;
    margin-bottom: 16px;
  }

  .card-preview {
    position: relative;
    width: full;
    @include ratio(16, 9);
    &:after {
      position: absolute;
      content: '';
      height: 100px;
      bottom: 0;
      width: 100%;
      left: 0;
      right: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
      z-index: 0;
      transition: all 0.3s;
    }
    .description {
      @include line-clamp(2, 1.456);
      min-height: 46px;
    }
  }
}

.router-wrapper {
  app-proposal-create {
    background-color: transparent;
    position: inherit;
    // display: flex;
    // justify-content: flex-end;
    // z-index: 1000;

    section {
      width: 100%;
      height: 100%;
      background-color: $white;
      border-radius: 8px;
    }
  }
}
