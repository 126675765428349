.pill {
  border-radius: 12px;
  font-size: 12px;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: left;
  background-color: $border;
  // text-transform: capitalize;
  // white-space: nowrap;
  // cursor: default;
  max-width: 100%;

  app-icon {
    width: 12px;
    height: 12px;
    margin-right: 0px;
    margin-bottom: -2px;
  }
  .span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.pill-success,
  &.approved {
    background-color: $success !important;
    color: $white !important;
    app-icon {
      fill: $white;
    }
  }
  &.status-active {
    background-color: lighten($success, 20%) !important;
  }
  app-local-loader {
    width: 14px;
    height: 14px;
    margin-top: -4px;
    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }
  &.lg {
    border-radius: 24px;
    font-size: 14px;
    padding: 8px 12px;
    app-icon {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
  &.selectable {
    background-color: rgba($border, 0.7);
    cursor: pointer;
    app-icon {
      opacity: 0.2;
    }
    &.selected {
      background-color: rgba($border, 1);
      app-icon {
        opacity: 1;
      }
    }
  }
}
.dark {
  .pill {
    background-color: $border-dark !important;
    &.pill-success,
    &.approved {
      background-color: $success !important;
      color: $white !important;

      app-icon {
        fill: $white;
      }
    }

    &.status-active {
      background-color: lighten($success, 20%) !important;
    }
  }
}
