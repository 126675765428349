.mat-snack-bar-container {
  background-color: $sidebar;
  color: $white;
  border-radius: 8px !important;
  &.error {
    background-color: $error;
  }
  &.info {
    background-color: $blue;
  }
  &.check {
    background-color: $success;
  }
}
.dark {
  background-color: $black;
}
