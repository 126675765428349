/* You can add global styles to this file, and also import other style files */
.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  width: 135px;
  max-width: none;
  border: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
  box-shadow: 0 32px 40px rgba($black, 0.05), 0 2px 10px rgba($black, 0.05);
  transition: all 0.3s;
  &.show-calendar {
    width: 647px;
  }
  &.single {
    width: 240px;
    @media (max-width: 540px) {
      height: 340px !important;
      top: calc(100% - 360px) !important;
    }
  }
}

.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  content: '';
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #fff;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #fff;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges,
.daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}
.daterangepicker .calendar-table .next,
.daterangepicker .calendar-table .prev {
  text-align: center;
}
.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th {
  line-height: 48px;
}

.daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 32px;
  font-size: 12px;
  border-radius: 16px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table thead tr:nth-child(2) th {
  line-height: 24px;
  background-color: rgba(#003654, 0.05);
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(#003654, 0.5);
  border-radius: 0;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #1290b1;
  border-color: transparent;
  color: inherit;
  color: #fff;
}

.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #4aacc6;
  border-color: transparent;
  color: #fff;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 18px 0 0 18px;
}

.daterangepicker td.end-date {
  border-radius: 0 18px 18px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 18px;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #1290b1;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 16px;
  border-top: solid thin rgba(#acb6c3, 0.2);
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 13px;
  border: solid thin rgba($blue, 0.5);
  line-height: 13px;
  padding: 8px;
  border-radius: 6px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  padding: 0;
  line-height: 30px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: none;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: none;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: none;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: none;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 16px 8px;
  width: 100%;
  border-right: solid thin rgba(#acb6c3, 0.2);
}

.daterangepicker .ranges li {
  font-size: 13px;
  padding: 4px 24px 4px 8px;
  cursor: pointer;
  position: relative;
  border-radius: 16px;
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 564px) {
    line-height: 48px;
    padding: 0 24px;
    border-radius: 32px;
    margin: 0;
    font-size: 20px;
  }
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: rgba($blue, 0.2);
  color: initial;

  &:after {
    content: url("data:image/svg+xml,%3Csvg width='10' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M8.263.324a1 1 0 011.553 1.254l-.08.098-4.588 5a1 1 0 01-1.336.125l-.097-.084L.303 3.402a1 1 0 011.298-1.516l.096.082L4.37 4.565 8.263.324z' fill='%23002940'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h10v7H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    position: absolute;
    top: 3px;
    right: 8px;
    @media (max-width: 564px) {
      top: 0px;
      right: 16px;
    }
  }
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges,
  .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges,
  .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

.cancelBtn {
  background: transparent;
  color: initial;
  text-transform: initial;
  border: none;

  &:hover {
    background: rgba(#acb6c3, 0.2);
    color: initial;
  }
}
.dark {
  /* You can add global styles to this file, and also import other style files */
  .daterangepicker {
    background-color: darken($body-dark, 15%);
    color: $black;
  }

  .daterangepicker:before {
    border-bottom: 7px solid darken($body-dark, 15%);
  }

  .daterangepicker:after {
    border-bottom: 6px solid darken($body-dark, 15%);
  }

  .daterangepicker.drop-up:before {
    border-top: 7px solid darken($body-dark, 15%);
  }

  .daterangepicker.drop-up:after {
    border-top: 6px solid darken($body-dark, 15%);
  }

  .daterangepicker .calendar-table .next span,
  .daterangepicker .calendar-table .prev span {
    color: #fff;
    border: solid black;
  }

  .daterangepicker .calendar-table thead tr:nth-child(2) th {
    background-color: rgba(#003654, 0.05);
    color: rgba(#003654, 0.5);
  }

  .daterangepicker td.available:hover,
  .daterangepicker th.available:hover {
    background-color: #1290b1;
    color: #fff;
  }

  .daterangepicker td.week,
  .daterangepicker th.week {
    font-size: 80%;
    color: #ccc;
  }

  .daterangepicker td.off,
  .daterangepicker td.off.in-range,
  .daterangepicker td.off.start-date,
  .daterangepicker td.off.end-date {
    background-color: #fff;
    color: #999;
  }

  .daterangepicker td.in-range {
    background-color: $primary-dark;
    color: #fff;
  }

  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background-color: $primary-dark;
    color: #fff;
  }

  .daterangepicker td.disabled,
  .daterangepicker option.disabled {
    color: #999;
  }

  .daterangepicker select.hourselect,
  .daterangepicker select.minuteselect,
  .daterangepicker select.secondselect,
  .daterangepicker select.ampmselect {
    background: $body-dark;
    border: 1px solid $body-dark;
  }

  .daterangepicker .calendar-time select.disabled {
    color: $body-dark;
  }

  .daterangepicker .drp-selected {
    border: solid thin rgba($primary-dark, 0.5);
  }

  .daterangepicker .ranges li:hover {
    background-color: $body-dark;
  }

  .daterangepicker .ranges li.active {
    background-color: rgba($primary-dark, 0.2);
    color: initial;
  }

  .cancelBtn {
    &:hover {
      background: rgba(#acb6c3, 0.2);
    }
  }
}

@media (max-width: 540px) {
  .daterangepicker {
    position: fixed !important;
    top: 0vh !important;
    left: 5vw !important;
    right: 5vw !important;
    bottom: 5vw !important;
    width: 90vw !important;
    height: 90vh !important;
    overflow-y: auto !important;

    .drp-calendar {
      width: 100% !important;
      max-width: 97%;
    }

    .drp-buttons {
      span {
        width: 100%;
        margin-bottom: 16px;
        text-align: center;
      }

      button {
        width: 100%;
        margin-left: 0 !important;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
