.dark {
  .bg-body {
    background-color: $body-dark !important;
  }
  .bg-white {
    background-color: $sidebar-dark;
  }
  .bg-sidebar {
    background-color: $sidebar-dark !important;
  }
  .border-gray-border {
    border-color: $border-dark !important;
  }
  .text-blue {
    color: $primary-dark !important;
  }
  .btn-primary {
    background-color: $primary-dark !important;
  }
  .backdrop-blur {
    background-color: rgba($body-dark, 0.4) !important;
  }
  .bg-backdrop {
    background-color: rgba($body-dark, 0.8) !important;
  }

  body {
    color: $white;
  }

  app-public-profile,
  app-full-profile {
    color: $white;
  }

  app-not-found {
    color: $white;
  }

  app-query-detail {
    color: $white;
  }
  app-query-result {
    color: $white;
  }
  app-review-detail {
    color: $white;
    li {
      color: $body-dark;
    }
  }

  app-review-item {
    color: $white;
    li {
      color: $body-dark;
    }
  }

  app-topbar {
    color: white !important;
    app-icon {
      fill: $white !important;
    }
  }

  app-nav {
    ul {
      li {
        &.menu-top-link-active {
          background-color: $primary-dark !important;

          &:after {
            background-color: $primary-dark !important;
          }
        }

        &:hover {
          background-color: $primary-dark !important;
        }
      }
    }
  }

  app-proposal-card-view {
    background-color: $black;
    .hover-wrapper {
      background-color: $black;
    }
    &.mobile-video-play {
      &:after {
        content: none;
      }
      .content {
        color: $white !important;
        h4 {
          color: $white !important;
        }
        .days {
          span {
            color: $white !important;
          }
          p {
            color: $white !important;
          }
          .days-progress_circle .progress-ring__circle_out {
            stroke: rgba($white, 0.2) !important;
          }
          .days-progress_circle .progress-ring__circle {
            stroke: $white !important;
          }
        }
        app-icon {
          fill: $white !important;
        }
        p {
          color: $white !important;
        }
      }
    }
  }

  .tab-type-block {
    .tabs-header {
      background-color: $body-dark !important;

      .tab-label {
        color: rgba($white, 0.5);
        &:hover {
          color: $white;
        }

        &.active {
          background-color: $sidebar-dark !important;
        }
      }
    }
  }
  .round {
    color: $white !important;
    .read-more {
      background-color: $primary-dark;
    }
  }
  app-icon {
    fill: $white;
  }
  .rules-expand {
    background-color: darken($body-dark, 15%) !important;
  }
  app-quiz {
    color: $white;
    app-quiz-question {
      .answer {
        background: $body-dark;
        .content {
          background-color: $body-dark !important;
        }
        &.success {
          .content {
            background: $success !important;
            &:hover {
              opacity: 1;
            }
          }
        }
        &.error {
          .content {
            background: $error !important;
            &:hover {
              opacity: 1;
            }
          }
        }
        &.warning {
          .content {
            background: $orange !important;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .pause-overlay {
        background-color: rgba($sidebar-dark, 0.6) !important;
      }
    }
  }
  app-proposal-moderation-list,
  app-administration {
    nav {
      button {
        color: $white !important;
        &.active {
          background-color: $primary-dark !important;
          color: $white;
          app-icon {
            fill: $white;
            opacity: 1;
          }
        }
      }
    }
  }

  .checkbox {
    /* Create a custom checkbox */
    color: $white;
    .checkmark {
      background-color: $body-dark !important;
      border: 1px solid $border-dark !important;
      border-radius: 4px;
    }
    &:hover input ~ .checkmark {
      background-color: $sidebar-dark !important;
    }
    input:checked ~ .checkmark {
      background-color: $primary-dark !important;
      border-color: $primary-dark !important;
    }
  }
  app-report-item {
    color: $white;
  }
  app-dcp-select,
  app-dcp-multiselect {
    .select-wrapper {
      background-color: $body-dark !important;
      color: $white !important;
      &:after {
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, $body-dark 100%) !important;
      }
    }
    .multiselect-wrapper {
      background-color: $body-dark !important;
      color: $white !important;

      &:after {
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, $body-dark 100%) !important;
      }
    }
    .multiselect-dropdown {
      .filter {
        overflow: hidden;
        span {
          background-color: $body-dark;
          input {
            border: none !important;
          }
        }
      }
    }
    &.link-type {
      div {
        &:first-child {
          color: $primary-dark !important;
        }
        .animated-arrow.arrow-text {
          &:before,
          &:after {
            background-color: $primary-dark !important;
          }
        }
      }

      div {
        background-color: transparent !important;
        &:after {
          background: transparent !important;
        }
      }
    }
  }
  .multiselect-dropdown {
    box-shadow: 0 8px 24px rgba($black, 0.6) !important;
    background: darken($body-dark, 15%) !important;
    .filter {
      border-color: $border-dark !important;
    }
    ul {
      li {
        color: $white !important;

        &.selected {
          color: $primary-dark !important;

          &:before {
            background-color: $primary-dark;
            border-color: $primary-dark;
          }
        }

        &:hover {
          background-color: darken($body-dark, 5%) !important;
        }
      }
    }
  }
  .select-dropdown {
    box-shadow: 0 8px 24px rgba($black, 0.6);
    background: darken($body-dark, 15%) !important;
    ul {
      li {
        color: $white !important;
        &.selected {
          color: $primary-dark !important;
          &:before {
            background-color: $primary-dark;
            border-color: $primary-dark;
          }
        }
        &:hover {
          background-color: darken($body-dark, 5%) !important;
        }
      }
    }
  }
  app-account {
    color: $white;
    .links {
      color: $white;
      &.active {
        color: $primary-dark;
      }
    }
  }
  app-potential-earnings {
    background-color: darken($body-dark, 5%) !important;
    color: $white !important;
    border-color: $border-dark !important;
    app-tabs {
      color: $white !important;
      border-color: $border-dark !important;
      .tabs-header {
        border-color: $border-dark !important;
        .tab-label {
          &.active {
            color: $primary-dark !important;
          }
        }
      }
      .tabs-body {
        color: $white !important;
        border-color: $border-dark !important;
        p {
          color: $white !important;
        }
        .potential-earnings {
          color: $white !important;
        }
      }
    }
    .activity-details {
      color: $white !important;
      border-color: $border-dark !important;
      li {
        color: $white !important;
        border-color: $border-dark !important;
      }
    }
  }
  app-topbar-menu {
    > ul {
      li {
        &.cursor-pointer {
          &:hover {
            color: $primary-dark !important;

            app-icon {
              fill: $primary-dark !important;
            }
          }
        }
      }
    }
  }
  app-proposal-create {
    background-color: rgba($sidebar-dark, 0.8) !important;
    section {
      background-color: $body-dark !important;
      color: $white;
      .round {
        background-color: $sidebar-dark;
        &:hover,
        &.active {
          background-color: lighten($sidebar-dark, 5%) !important;
        }
        &.active {
          &:before {
            border-color: $primary-dark !important;
          }

          &:after {
            background-color: $primary-dark !important;
          }
        }

        button {
          color: $primary-dark !important;
        }
      }
      .add-media {
        background-color: $sidebar-dark !important;
        border-color: $border-dark !important;
        .image-container {
          background: $body-dark;
        }
      }
      .add-media-sect {
        background-color: $body-dark !important;
      }

      app-bounty-info {
        background-color: $sidebar-dark !important;
        .subtable {
          background-color: $body-dark !important;
        }
      }
      .img-cropper-container {
        background-color: $body-dark !important;
        .cropper-content {
          background-color: $sidebar-dark !important;
        }
      }
    }
  }
  app-query-create {
    background-color: rgba($sidebar-dark, 0.8) !important;

    section {
      background-color: $body-dark !important;
      color: $white;
      .bg-white {
        background-color: $body-dark !important;
      }
    }
  }
  app-tabs {
    &.tabs-create-panel {
      .tabs-header {
        background-color: $sidebar-dark !important;
        .tab-label {
          border-color: $sidebar-dark !important;
          &.active {
            color: $primary-dark !important;
            background-color: $body-dark !important;
            border-left-color: $body-dark !important;
            border-bottom-color: $body-dark !important;
            app-icon {
              fill: $primary-dark !important;
            }
          }
        }
      }
    }
    &.tabs-type-link {
      .tab-label {
        color: rgba($white, 0.6) !important;
        transition: all 0.3s;
        app-icon {
          fill: rgba($white, 0.6) !important;
          transition: all 0.3s;
        }
        &:hover {
          color: $white !important;
          app-icon {
            fill: $white !important;
          }
        }
        &.active {
          color: $primary-dark !important;
          cursor: default;
          app-icon {
            fill: $primary-dark !important;
          }
          &:after {
            background-color: $primary-dark;
          }
        }
      }
    }
  }
  app-logo {
    &.logo-black {
      fill: $white !important;
      svg {
        fill: $white !important;
        path {
          fill: $white !important;
        }
      }
    }
  }
  .mat-dialog-container {
    color: $white;
  }
  app-option {
    .opt-holder {
      background-color: $sidebar-dark !important;
      border: dashed thin $border-dark !important;
      input,
      textarea {
        border: solid thin $border-dark !important;
        background-color: darken(rgba($sidebar-dark, 0.7), 10%) !important;
        color: $white !important;
      }
      .image-holder {
        background-color: $body-dark !important;
      }
    }
  }
  app-proposal-extend-dialog {
    app-logo {
      svg {
        fill: $white !important;
      }
    }
  }
  app-filter-select {
    color: $white !important;
  }
  app-proposal-detail {
    color: $white !important;
  }
  app-review {
    background-color: $sidebar-dark !important;
    color: $white;
  }
  app-reviews app-review .review {
    background-color: $sidebar-dark !important;
    .review-header {
      border-color: $border-dark !important;
      .btn-icon {
        background-color: transparent !important;
        &.active {
          background-color: $primary-dark !important;
        }
        app-icon {
          fill: $white !important;
        }
      }
    }

    .review-content {
      border-color: $border-dark !important;
    }

    p {
      color: $white !important;
      app-icon {
        fill: $white !important;
      }
    }
  }
  app-proposal-teaser {
    app-reviews app-review .review {
      background-color: $body-dark !important;
      .review-header {
        border-color: $border-dark;
      }
      .review-content {
        border-color: $border-dark;
      }
    }
  }
  app-creative-query-question {
    .option-box {
      background-color: lighten($body-dark, 3%) !important;
    }
  }
  app-creative-query-actions {
    background-color: lighten($body-dark, 7%) !important;
  }

  app-creative-query-result-table {
    .table-wrapper {
      background: lighten($body-dark, 7%) !important;
      color: #fff;
    }
    .tb-row.header {
      border-bottom: $border-dark !important;
      background-color: lighten($body-dark, 7%) !important;
    }
    .tb > div {
      border-right: $border-dark !important;
    }
  }

  app-local-loader {
    svg {
      path {
        stroke: $white !important;
      }
    }
  }
  app-reputation-board {
    color: $white;
  }
  app-proposal-result {
    color: $white !important;
  }
  app-payment {
    color: $white;
    .payment-methood {
      li {
        border-color: $white !important;
        &.selected {
          background-color: $primary-dark !important;
          border-color: $primary-dark !important;
        }
      }
    }
    .cc-cards {
      li {
        background-color: lighten($body-dark, 3%) !important;
        border-color: $border-dark !important;
      }
    }
  }

  app-details {
    .pill {
      background-color: $border-dark !important;
    }
  }
  app-create-proposal-round {
    color: $white;
  }

  app-review-create {
    background: linear-gradient(0deg, rgba($sidebar-dark, 1) 40%, rgba($sidebar-dark, 0.55) 100%) !important;
    .score-inputs {
      background-color: rgba($body-dark, 0.6) !important;
    }
  }

  app-dcp-date-picker {
    input {
      &:disabled {
        background-color: transparent !important;
        border-color: rgba($border-dark, 0.1) !important;
      }
    }
  }

  app-dcp-select {
    .disabled {
      background-color: transparent !important;
      border-color: rgba($border-dark, 0.1) !important;
      span {
        display: none;
      }
      &:after {
        content: none !important;
      }
    }
  }
  app-filter-select {
    .multiselect,
    .singleselect {
      ul {
        .selected {
          &:before {
            background-color: $primary-dark !important;
            border-color: $primary-dark !important;
          }
          label {
            color: $primary-dark !important;
          }
          &:hover {
            label {
              color: $primary-dark !important;
            }
          }
        }
        li {
          &:hover {
            label {
              color: $primary-dark !important;
            }
          }
        }
      }
    }
  }
  .table-newlook .tbody:nth-child(odd) {
    background-color: $sidebar-dark !important;
  }
  .table-newlook .tbody {
    border-color: $border-dark !important;
  }
  app-no-items {
    h3 {
      color: $white !important;
    }
  }
  .customScroll {
    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(256, 256, 256, 0);
      background-color: transparent;
    }

    ::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba($white, 0.6) !important;
    }
  }
  app-login,
  app-register,
  app-forgot {
    color: $white !important;
  }
}
.gray-scale {
  app-proposal-card-view {
    .filter-gray-scale {
      filter: grayscale(100%);
    }
    h3 {
      color: $white !important;
    }
  }
}
