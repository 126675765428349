.table-newlook {
  background: $white;
  border-radius: 8px;
  overflow: hidden;

  .thead {
    display: flex;
    font-size: 11px;
    text-transform: uppercase;
    width: 100%;
    // border-bottom: solid thin $light-theme-background;
    line-height: 45px;
    font-weight: 400;
    z-index: 1;
    background-color: $white;
    position: sticky;
    top: 0;
    li {
      line-height: 14px;
      padding: 16px;
      display: flex;
      align-items: center;
    }
  }

  .tbody {
    display: flex;
    justify-content: space-between;
    width: 100%;
    z-index: 0;

    li {
      display: flex;
      align-items: center;
      min-height: 54px;
      padding: 8px 16px;

      label {
        display: none;
      }

      mat-icon {
        font-size: 20px;
        vertical-align: -2px;
      }
    }

    &:nth-child(even) {
      background-color: lighten($body, 3%);
      @media (max-width: 991px) {
        background-color: $white;
      }
    }

    &.clickable {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        // background-color: $light-theme-background;
      }

      &:active {
        // background-color: darken($light-theme-background, 5%);
      }
    }
  }

  @media (max-width: 991px) {
    background: transparent;
    box-shadow: none;

    .thead {
      display: none;
    }

    .tbody {
      flex-wrap: wrap;
      border-radius: 6px;
      margin-bottom: 16px;
      border: solid thin $border;

      &:nth-child(even) {
        background-color: $white;
      }

      li {
        min-width: 30%;
        flex-grow: 1;
        position: relative;
        padding: 24px 8px 8px;
        padding-top: 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        justify-content: flex-start;

        label {
          display: block;
          position: absolute;
          top: 8px;
          font-size: 9px;
          text-transform: uppercase;
          left: 8px;
          opacity: 0.6;
          text-align: left;
        }
      }
    }
  }

  @media (max-width: 640px) {
    .tbody {
      li {
        min-width: 50%;
      }
    }
  }
}

.dark {
  .table-newlook {
    background: $sidebar-dark;
    color: $white !important;

    .thead {
      border-bottom: solid thin $border-dark;
      background: $sidebar-dark;
    }

    .tbody {
      &:nth-child(even) {
        background-color: lighten($sidebar-dark, 6%) !important;
      }

      &:hover {
        background-color: lighten($sidebar-dark, 4%) !important;
      }

      &:active {
        background-color: darken($sidebar-dark, 5%) !important;
      }
    }

    @media (max-width: 991px) {
      background: transparent !important;

      .tbody {
        background-color: $sidebar-dark;

        &:nth-child(even) {
          background-color: $sidebar-dark;
        }

        li {
          border: solid thin $border-dark !important;
        }
      }
    }
  }
}
