html,
body {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: $text;
  /* mobile viewport bug fix */
  position: fixed;
  top: 0;
  left: 0;
}
html {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
  @media (max-width: 768px) {
    min-height: calc(var(--vh, 1vh) * 100);
    height: calc(var(--vh, 1vh) * 100);
    max-height: calc(var(--vh, 1vh) * 100);
  }
}
body {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
  app-root {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  app-sidebar {
    min-width: 240px;
    max-width: 240px;
  }
  main {
    width: calc(100% - 240px);
    min-width: calc(100% - 240px);
    max-width: calc(100% - 240px);
    transition: all 0.3s;
    .router-wrapper {
      height: calc(100% - 64px);
      width: 100%;
      @media (max-width: 991px) {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        overflow: hidden;
        height: 100%;
      }
    }
    @media (max-width: 991px) {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: calc(100% - 64px) !important;
      margin-top: 64px;
      border-top-right-radius: 16px;
      border-bottom-left-radius: 0 !important;
    }
    app-topbar {
      .mobile-user {
        display: none;
        width: 40px;
        height: 40px;
        min-width: 40px;
      }
      @media (max-width: 991px) {
        margin-top: -64px;
        border-bottom: none;
        .desktop-menu {
          display: none !important;
        }
        .mobile-user {
          display: block;
        }
      }
    }
  }
  app-sidebar {
    transition: all 0.3s;
    @media (max-width: 991px) {
      margin-left: -240px;
      min-width: 240px;
      app-logo {
        display: none !important;
      }
      app-nav {
        margin-top: 64px;
        justify-content: flex-start !important;
      }
    }
  }
  app-topbar {
    .show-hide-menu {
      app-icon.left-menu-open {
        display: block;
      }
      app-icon.left-menu-close {
        display: none;
      }
    }
  }

  .left-side-open {
    app-sidebar {
      margin-left: 0;
    }
    app-topbar {
      .show-hide-menu {
        app-icon.left-menu-open {
          display: none;
        }
        app-icon.left-menu-close {
          display: block;
        }
      }
    }
  }
  .user-side-open {
    app-topbar {
      .mobile-user {
        display: none !important;
      }
      .user-hide-menu {
        display: flex !important;
      }
    }
  }
}
.mini {
  main {
    width: calc(100% - 80px);
    min-width: calc(100% - 80px);
    max-width: calc(100% - 80px);
    transition: all 0.3s;
  }

  app-sidebar {
    transition: all 0.3s;
    width: 80px;
    min-width: 80px;
    max-width: 80px;
    app-nav {
      ul {
        padding: 24px 16px;
        transition: all 0.3s;
        li {
          transition: all 0.3s;
          padding: 0 14px !important;
          app-icon {
            min-width: 20px;
          }
          label {
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s;
          }
          &:after {
            content: none !important;
          }
        }
      }
    }
    app-logo {
      width: 230px;
      transition: all 0.3s;
      svg {
        path.logo {
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s;
        }
      }
    }
    app-footer {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      transform: translateX(-50px);
      bottom: 24px;
      left: 100%;
      width: 210px;
      transition: all 0.3s;
      background-color: $sidebar;
      margin-left: 16px;
      border-radius: 8px;

      &.show {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        z-index: 1;
      }
    }
    .btn-icon {
      display: flex !important;
      margin: 24px auto;
    }
  }
  &.left-side-open {
    main {
      width: calc(100% - 240px);
      min-width: calc(100% - 240px);
      max-width: calc(100% - 240px);
    }
    app-sidebar {
      width: 240px;
      min-width: 240px;
      max-width: 240px;
      app-nav {
        ul {
          padding: 24px;
          li {
            padding: 0 16px !important;
            app-icon {
              min-width: 20px;
            }
            label {
              visibility: visible;
              opacity: 1;
            }
            &:after {
              content: '' !important;
            }
          }
        }
      }
      app-logo {
        width: 169px;
        svg {
          path.logo {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
}
.user-side-open {
  main {
    margin-left: -256px;
  }
}
input,
textarea {
  &:focus {
    outline: none;
  }
}
.transitions-all {
  transition: all 0.3s;
}
.backdrop-blur {
  background-color: rgba($sidebar, 0.4);
  backdrop-filter: blur(4px);
}

#cdk-describedby-message-container {
  display: none;
}
.title-trun {
  span {
    display: inline-block;
    white-space: nowrap;
  }
  &.title-truncate {
    overflow: hidden;
    span {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      span {
        display: inline-block;
        overflow: visible;
        max-width: 100%;
        text-overflow: unset;
        animation: 5s linear infinite animate;
      }
    }
  }
}

@keyframes animate {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    background-color: translateX(0);
  }
}
.transitions-all {
  transition: all 0.3s;
}
.customScroll {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: transparent;
  }

  ::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba($text, 0.6);
  }
}

.img-placeholder {
  background-color: $body;
  background-image: url("data:image/svg+xml,%3Csvg width='47' height='43' viewBox='0 0 47 43' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.5 13.0087L28.1666 4.79158C27.5478 3.25462 25.8002 2.51033 24.2632 3.12915L4.7829 10.9725C3.24595 11.5913 2.50165 13.3389 3.12048 14.8759L10.9638 34.3562C11.5826 35.8931 13.3302 36.6374 14.8672 36.0186L18.5 34.5087' stroke='%23ACABAB' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M42.5 13.5087H21.5C19.8431 13.5087 18.5 14.8518 18.5 16.5087V37.5087C18.5 39.1655 19.8431 40.5087 21.5 40.5087H42.5C44.1569 40.5087 45.5 39.1655 45.5 37.5087V16.5087C45.5 14.8518 44.1569 13.5087 42.5 13.5087Z' stroke='%23ACABAB' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M26.75 24.0087C27.9926 24.0087 29 23.0013 29 21.7587C29 20.516 27.9926 19.5087 26.75 19.5087C25.5074 19.5087 24.5 20.516 24.5 21.7587C24.5 23.0013 25.5074 24.0087 26.75 24.0087Z' stroke='%23ACABAB' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M44.6 30.7587L38 24.0087L20 39.0087' stroke='%23ACABAB' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E ");
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media print {
  .body {
    opacity: 0;
    visibility: hidden;
  }
  .round {
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 640px) {
  .plyr--video.plyr--hide-controls .plyr__controls {
    opacity: 1 !important;
    pointer-events: all !important;
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important;
  }
}
.fulscreen.on {
  .slider {
    .slides {
      .slide {
        img {
          object-fit: contain !important;
        }
      }
    }
  }
}
