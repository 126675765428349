.dropdown,
.hover-dropdown {
  cursor: pointer;
  position: relative;
  outline: 0;

  .drop-menu {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 15px);
    transition: all 0.1s;
    min-width: 190px;
    max-width: 290px;
    background-color: $white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 24px rgba($black, 0.25);
    outline: 0;
    z-index: 11000;

    .menu-button {
      height: 40px;
      outline: 0;
      line-height: 40px;
      display: block;
      padding: 0 16px;
      border-radius: 8px;
      transition: all 0.1s;
      white-space: nowrap;
      outline: 0;
      width: 100%;
      text-align: left;
      color: $text !important;
      font-weight: 400;
      &:hover {
        background-color: $body;
      }
      app-icon {
        margin-bottom: -2px;
        width: 16px;
        height: 16px;
        opacity: 0.5;
        margin-right: 16px;
        &.next {
          opacity: 1;
          position: absolute;
          right: 0px;
          top: 10px;
          width: 20px;
          height: 20px;
        }
      }
      &:active {
        background-color: $blue;
        color: $white !important;
        app-icon {
          fill: $white;
          opacity: 1;
          svg {
            fill: $white;
            path {
              fill: $white;
            }
          }
        }
      }
    }
  }
  &.right {
    .drop-menu {
      left: inherit;
      right: 0;
      transform: translate(0, 15px);
    }
  }

  &.left {
    .drop-menu {
      left: 0;
      transform: translate(0, 15px);
    }
  }
  // &:active {
  //   background-color: $blue !important;
  //   border-color: $blue !important;
  // }
}
.dropdown {
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, 0px);
    &.right {
      transform: translate(0, 0);
    }
    &.left {
      left: inherit;
      right: 100%;
      transform: translate(0, 0);
    }
  }
}
.hover-dropdown {
  &:hover {
    .drop-menu {
      opacity: 1 !important;
      visibility: visible;
      transform: translate(-50%, 0px);
    }

    &.right {
      .drop-menu {
        transform: translate(0, 0);
      }
    }
    &.left {
      .drop-menu {
        transform: translate(0, 0);
      }
    }
  }
}
app-user-menu {
  .menu-button {
    color: $text !important;
  }
  .theme {
    line-height: 48px;
    position: relative;
    width: 100%;
    border-radius: 8px;
    text-align: left;

    app-icon {
      width: 20px;
      height: 20px;
      margin-right: 16px;
      margin-bottom: -2px;
      opacity: 0.5;
    }

    &:before,
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
    }

    &:before {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      border: solid thin $border;
      right: 16px;
    }

    &:after {
      right: 20px;
      width: 16px;
      height: 16px;
      border-radius: 16px;
    }

    &:hover {
      background-color: $body;
    }

    &.selected {
      &:before {
        border-color: $blue;
      }

      &:after {
        background-color: $blue;
      }
    }

    &.grayscale {
      // &:hover {
      //   background-color: transparent;
      // }

      &:before {
        border-radius: 4px;
      }
      &:after {
        width: 6px;
        height: 10px;
        right: 23px;
        border-radius: 0;
        top: 18px;
        border: solid transparent;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
      &.selected {
        &:before {
          background-color: $blue;
          border-color: $blue;
        }
        &:after {
          border-color: $white;
          border-width: 0 3px 3px 0;
        }
      }
    }
  }
}
.dropdown-menu {
  .menu-button {
    color: $text !important;
  }
}
.dark {
  .dropdown,
  .hover-dropdown {
    .drop-menu {
      background-color: darken($body-dark, 15%);
      box-shadow: 0 4px 24px rgba($black, 0.6);

      .menu-button {
        color: $white !important;
        position: relative;
        a {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          color: $white !important;
        }
        app-icon {
          fill: $white;

          svg {
            fill: $white;

            path {
              fill: $white;
            }
          }
        }
        &:hover {
          background-color: darken($primary-dark, 15%);
        }

        &:active {
          background-color: $primary-dark;
          color: $white !important;
        }
      }
    }
    .theme {
      &:before {
        border: solid thin $border-dark;
      }
      &:hover {
        background-color: $body-dark;
      }
      &.selected {
        &:before {
          border-color: $primary-dark;
        }
        &:after {
          background-color: $primary-dark;
        }
      }
    }
  }
  .dropdown-menu {
    background-color: darken($body-dark, 15%) !important;
    box-shadow: 0 4px 24px rgba($black, 0.6) !important;
    .menu-button {
      color: $white !important;
    }
    h3 {
      color: $white !important;
    }
    button,
    a {
      color: $white !important;
      app-icon {
        fill: $white !important;

        svg {
          fill: $white !important;

          path {
            fill: $white !important;
          }
        }
      }
      &:hover {
        background-color: darken($primary-dark, 15%) !important;
      }

      &:active {
        background-color: $primary-dark !important;
        color: $white !important;
      }
    }
  }
  app-user-menu {
    .menu-button {
      color: $white !important;
    }
  }
}
@media (max-width: 991px) {
  app-user-menu {
    .icon-white {
      fill: $white !important;
    }
    .theme {
      app-icon {
        fill: $white !important;
      }
      &:hover {
        background-color: rgba($body, 0.1);
      }
    }
  }
}

.cdk-overlay-container {
  z-index: 11000 !important;
}
