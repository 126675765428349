.search {
  position: relative;
  outline: 0;
  input {
    width: 0;
    opacity: 0;
    height: 32px;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    outline: none;
  }
  .close {
    display: none;
  }
  &.open {
    width: 250px;
    min-width: 250px;
    max-width: 250px;
    z-index: 0;
    justify-content: space-between !important;
    padding: 0 8px;
    border-color: $blue !important;
    input {
      width: 100%;
      opacity: 1;
      z-index: 0;
      visibility: visible;
      background-color: transparent;
      border-radius: 8px;
      border: none;
      padding: 0 40px;
    }
    .close {
      display: block;
    }
  }
}
